body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*, *::before, *::after {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fullscreen {
  background: black;
  position: absolute;
  z-index: -2;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.background {
  background: rgb(213,119,235);
  background: linear-gradient(45deg, rgba(218,177,119,1) 0%, rgba(97,182,219,1) 50%, rgba(213,119,235,1) 100%);
  display: flex;
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: -1;
}

.background-animation {
  -webkit-animation: color 2s;
          animation: color 2s;
}

@-webkit-keyframes color {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes color {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

.main {
  text-align: center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.option {
  /* background: rgb(140, 96, 150); */
  padding: 0.25em 1em 0.25em 1em;
  display: flex;
  justify-content: start;
  align-items: center;
}

.profile {
  display: flex;
  flex-direction: column;
}

.button {
  background-color: #c8def6;
  border-style: solid;
  border-color: #282c34;
  border-radius: 0.5em;
  color: black;
  padding: 1em 1.4em;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 700;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.button:active{
  background-color: #5b6064;
}

.timepicker{
  font-weight: 700;
  margin-right: 0.5em;
  margin-left: 0.5em;
}

.color_wrapper {
  display: flex;
  width: 100% - (1em);
  overflow-y: scroll;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.color_selection {
  min-width: 6em;
  width: 6em;
  min-height: 6em;
  height: 6em;
  border-radius: 1em;
  border-style: solid;
  margin-right: 0.5em;
}

.color_picker_modal_parent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.color_picker_modal_show {
  background-color: #bacee4ad;
  width: 2em;
  height: 2em;
  max-width: 2em;
  max-height: 2em;
  margin-top: 0.2em;
  margin-right: 0.2em;
  border-radius: 0.6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selected {
  border-color: aliceblue;
}

.ml1 {
  margin-left: 1em;
}

.mt1 {
  margin-top: 1em;
}

h1{
  -webkit-margin-before: 0.25em;
          margin-block-start: 0.25em;
  -webkit-margin-after: 0.25em;
          margin-block-end: 0.25em;
}

h2{
  -webkit-margin-before: 0.25em;
          margin-block-start: 0.25em;
  -webkit-margin-after: 0.25em;
          margin-block-end: 0.25em;
}

h3{
  -webkit-margin-before: 0.25em;
          margin-block-start: 0.25em;
  -webkit-margin-after: 0.25em;
          margin-block-end: 0.25em;
}

/* MODAL CSS */

.modal_main {
  display: flex;
  position: absolute;
  width:100vw;
  height:100vh;
  top: 0;
  left: 0;
  padding: 0;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.575);
  z-index: 1;
  justify-content: center;
  align-items: center;
}

.modal_main_child {
  position: absolute;
  top: 1em;
  width: 90vw;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background: linear-gradient(135deg, #e389b8 40%, #74bff1 95%);
  border-radius: 0.5rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
}

.modal-header {
  justify-content: center;
  align-content: center;
  height: 5em;
  background-color: rgba(236, 116, 116, 0.479);
}

.modal-body {

  height: -webkit-fit-content;

  height: -moz-fit-content;

  height: fit-content;
  background-color: rgba(240, 50, 50, 0.479);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modal-footer {
  justify-content: center;
  align-content: center;
  height: 5em;
  background-color: rgba(158, 116, 236, 0.479);
  display: flex;
  flex-direction: row;
}

.modal-footer-button {
  width: 50%;
  height: 100%;
  padding: 0.5em;
}

.modal-footer-button-child {
  width: 100%;
  height: 100%;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color:#15151536;
}

.modal-color-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  height: 7em;
  overflow: scroll;
}

.modal-tile-add {
  min-width: 6em;
  width: 6em;
  min-height: 6em;
  height: 6em;
  border-radius: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(214, 209, 209, 0.606);
}
.modal-color-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px + 2em;
  padding-top: 1em;
  padding-bottom: 1em;
}

/* SWITCH CSS */

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .slider {
  background-color:#282c34;
}

input:focus + .slider {
  box-shadow: 0 0 1px #282c34;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

